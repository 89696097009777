import React, { useEffect, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { dbGetTransactions } from '../utils/db-functions'
import { Helmet } from "react-helmet-async";
import uniqid from 'uniqid';

//import './history.scss'

import LoadMore from "../components/LoadMore";

export default function History() {
    const user = useSelector((state) => state.user.data)
    const [transactions, setTransactions] = useState([]);
    const [currentType, setCurrentType] = useState('');
    const [type, setType] = useState('game');
    const [pages, setPages] = useState({
        page: 1,
        total: null
    })

    const fetchPage = (transactionType, pageNumber) => {
        const currentTransactions = pageNumber === 1 ? [] : transactions;

        dbGetTransactions(transactionType, pageNumber, user.id)
            .then(res => {
                if (!res.data) { return; }
                console.log(res.data)
                setTransactions([...currentTransactions, ...res.data.docs])
                setCurrentType(transactionType);
                setPages({ page: pageNumber, total: res.data.totalPages })
            })
    }

    useEffect(() => {
        if (type !== '' && type !== currentType) { fetchPage(type, 1); }
    }, [type, pages.page])

    return (
        <>
            <Helmet>
                <title>History | Cherry Spins Casino</title>
            </Helmet>
            <section className="history-container">
                <div className="history-section">
                    {/* <h2 className="h4 h4-bold mb-3">Transaction history</h2> */}
                    <ButtonGroup aria-label="Transaction history" className="w-100" >
                        <Button className="btn-secondary btn-history-transaction" onClick={() => { setType('game') }}>Game</Button>
                        <Button className="btn-secondary btn-history-transaction" onClick={() => { setType('payment') }}>Payment</Button>
                        <Button className="btn-secondary btn-history-transaction" onClick={() => { setType('bonus') }}>Bonus</Button>
                    </ButtonGroup>

                    {
                        transactions?.length > 0 && type === 'game' && (
                            <>
                                <h2 className="h5 mt-3 mid-emphasis">Game transactions</h2>
                                {
                                    transactions.map((transaction, i) => {
                                        return (
                                            <GameTransaction key={uniqid()} transaction={transaction} />
                                        )
                                    })
                                }
                            </>
                        )
                    }

                    {
                        transactions?.length > 0 && type === 'payment' && (
                            <>
                                <h2 className="h5 mt-3 mid-emphasis">Payment transactions</h2>
                                {
                                    transactions.map((transaction) => {
                                        return (
                                            <PaymentTransaction key={uniqid()} transaction={transaction} />
                                        )
                                    })
                                }
                            </>
                        )
                    }

                    {
                        transactions?.length > 0 && type === 'bonus' && (
                            <>
                                <h2 className="h5 mt-3 mid-emphasis">Bonus transactions</h2>
                                {
                                    transactions.map((transaction) => {
                                        return (
                                            <BonusTransaction key={uniqid()} transaction={transaction} />
                                        )
                                    })
                                }
                            </>
                        )
                    }

                    <LoadMore
                        isVisible={pages.total !== null && pages.page < pages.total}
                        onClick={() => { fetchPage(type, pages.page + 1) }}
                    />
                </div>
            </section>
        </>
    );
}

function GameTransaction({ transaction, ...props }) {
    return (
        <div {...props}>
            <div className="transaction">
                <div className="transaction-row">
                    <div>
                        <p className="mb-0 mt-0 transaction-label">Action</p>
                        <p className="mb-0 mt-0 transaction-value">{transaction?.type}</p>
                    </div>
                    <div>
                        <p className="mb-0 mt-0 transaction-label">Amount</p>
                        <p className="mb-0 mt-0 transaction-value">{transaction?.amount}</p>
                    </div>
                    <div className="text-end">
                        <p className="mb-0 mt-0 transaction-label">Balance after</p>
                        <p className="mb-0 mt-0 transaction-value">{transaction?.balance}</p>
                    </div>
                </div>
                <div className="transaction-row">
                    <div>
                        <p className="mb-0 mt-0 transaction-label">Game</p>
                        <p className="mb-0 mt-0 transaction-value">{transaction?.gameName}</p>
                    </div>
                    <div className="text-end">
                        <p className="mb-0 mt-0 transaction-label">Date</p>
                        <p className="mb-0 mt-0 transaction-value">{transaction?.createdAt?.split('T')?.join(', ')?.replace('Z', '')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

function PaymentTransaction({ transaction, ...props }) {
    return (
        <div {...props}>
            <div className="transaction">
                <div className="transaction-row">
                    <div>
                        <p className="mb-0 mt-0 transaction-label">Action</p>
                        <p className="mb-0 mt-0 transaction-value">{transaction?.intent}</p>
                    </div>
                    <div>
                        <p className="mb-0 mt-0 transaction-label">Amount</p>
                        <p className="mb-0 mt-0 transaction-value">{transaction?.amount}</p>
                    </div>
                    <div className="text-end">
                        <p className="mb-0 mt-0 transaction-label">Date</p>
                        <p className="mb-0 mt-0 transaction-value">{transaction?.createdAt?.split('T')?.join(', ')?.replace('Z', '')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

function BonusTransaction({ transaction, ...props }) {
    return (
        <div {...props}>
            <div className="transaction">
                <div className="transaction-row">
                    <div>
                        <p className="mb-0 mt-0 transaction-label">Promocode</p>
                        <p className="mb-0 mt-0 transaction-value">{transaction?.promoCode}</p>
                    </div>
                    <div>
                        <p className="mb-0 mt-0 transaction-label">Status</p>
                        <p className="mb-0 mt-0 transaction-value">{transaction?.status}</p>
                    </div>
                    <div className="text-end">
                        <p className="mb-0 mt-0 transaction-label">Date</p>
                        <p className="mb-0 mt-0 transaction-value">{transaction?.createdAt?.split('T')?.join(', ')?.replace('Z', '')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}