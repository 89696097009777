import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import socketIOClient from "socket.io-client";
import { HelmetProvider } from "react-helmet-async";

/** Reducers */
import { checkUserAsync, updateUser } from './reducers/user';
import { getGlobalContentAsync } from './reducers/global-content';
import { GetGameCategoriesAsync } from './reducers/game-categories';
import { GetPromotionsAsync } from './reducers/promotions';
import { GetBonusesAsync } from './reducers/bonuses';

/** Components & utilities*/
import Navigation from './components/Navigation';
import ScrollRestoration from './components/ScrollRestoration';
import SmoothScroll from './components/SmoothScroll';
import RenderRoutes from './routes';
import IdleTimer from './components/IdleTimer';
import LoadingScreen from './components/LoadingScreen';
import { dbBaseURL, dbCheckCountryAndLanguage } from './utils/db-functions';
import { voluumCheckURL, voluumSendPostback, voluumGetClickID } from './utils/voluum';
import { incomeAccessCheckURL, incomeAccessSetBtag } from './utils/income-access';
import IPBlock from './components/IPBlock';
import { setTrafficSource } from './utils/traffic-source';

import './App.scss';

function App() {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const user = useSelector((state) => state.user.data);
	const userLoading = useSelector((state) => state.user.isLoading);
	const userLoggedIn = useSelector((state) => state.user.isLoggedIn);
	const globalContentLoading = useSelector((state) => state.globalContent.isLoading);

	/** Get country and language from URL */
	const [, localeURL, ...restURL] = location.pathname.split('/');
	let [urlCountry, urlLanguage] = localeURL.split('-');

	useEffect(() => {

		/** Check if there is a logged in user */
		if (userLoading) { dispatch(checkUserAsync()); }

		/** Once we know if the user is logged in or not, we load other content */
		if (!userLoading && globalContentLoading) {

			const userCountry = user.country ? user.country.ISOcode : undefined;

			dbCheckCountryAndLanguage(userCountry || urlCountry, urlLanguage)
				.then(({ country, language }) => {

					/** If country or language is not supported redirect to default values */
					if (country.ISOcode !== urlCountry || language !== urlLanguage) {
						navigate(`/${country.ISOcode}-${language}/${restURL.join('/')}${location.search}${location.hash}`, { replace: true });
					}
 
					/** Global content */
					dispatch(getGlobalContentAsync(country, language));

					/** Promotions */
					dispatch(GetPromotionsAsync(country, language, user));

					/** Categories */
					dispatch(GetGameCategoriesAsync(country, language));

					/** Bonuses */
					dispatch(GetBonusesAsync(country, language));

				})
				.catch(err => { console.log(err) })
		}

		/** Socket IO init */
		const socket = socketIOClient(dbBaseURL, { upgrade: false, transports: ['websocket'] });

		/** Socket IO listen to updates */
		if (userLoggedIn) {
			socket.on("userUpdate", data => {
				if (data.id === user.id) {
					dispatch(updateUser({ data: { ...user, ...data } }));
				}
			});

			socket.on("depositComplete", data => {
				if (data.id === user.id) {
					try {
						voluumSendPostback('dep-complete', Number.parseFloat(Number.parseFloat(data.wagerBalance) - Number.parseFloat(user.wagerBalance || 0)));
					} catch (error) { }
					dispatch(updateUser({ data: { ...user, ...data } }));
				}
			});
		}

		/** Voluum integration */
		if (voluumCheckURL(location.search)) {

			/** Get and save player click id */
			const cid = voluumGetClickID(location.search);
			window.sessionStorage.setItem('cid', cid);
		}

		/** Income access integration */
		if (incomeAccessCheckURL(location.search)) {

			/** Set income access cookie */
			incomeAccessSetBtag(location.search);
		}

		/** Traffic source integration */
		setTrafficSource(location.search);

		return () => { if (socket.connected) { socket.disconnect() } };

	}, [userLoading, globalContentLoading, userLoggedIn]);

	return (
		<>
			<ScrollRestoration />
			<SmoothScroll />
			{
				userLoading || globalContentLoading ? <LoadingScreen /> : (
					<>
						{
							userLoggedIn && <IdleTimer />
						}
						<HelmetProvider>
							<Navigation />
							<RenderRoutes />
							<IPBlock />
						</HelmetProvider>
					</>
				)
			}
		</>
	);
}

export default App;